<template>
    <div style="min-height: calc(100vh + 10px)" :class="wrapClass">

        <!-- <bClick v-bind:backTo="backTo"></bClick> -->

        <AdFinity v-if="adfinityTrigger"></AdFinity>
        <SendGoals></SendGoals>
        <FirebaseUnit v-if="firebaseTrigger && !dfTrigger"></FirebaseUnit>

        <GetContent v-on:contentGetted="directFeedReady" v-bind:dataFeed="dfSource"></GetContent>
        <!-- <div v-if="pwaTrigger"></div> -->
        <FlowLogic v-if="logicTrigger"></FlowLogic>

        <!-- v-if="luckyTrigger && !dfTrigger" -->
        <GetLucky v-if="dfTrigger"></GetLucky>

        <!-- Бегущая строка (Всем и Всегда) -->
        <TickerBlock 
            v-bind:goalName="'TickerBlock'"
            v-bind:dataFeed="dataFeed"
        ></TickerBlock>
        <!-- Верхний блок -->
        <section v-if="jumbtronTrigger">
            <b-container>
                <b-row>
                    <b-col>
                        <BlockJumbotron 
                            v-bind:dataFeed="dataFeed"
                            v-bind:goalName="'TopBlock'"
                        ></BlockJumbotron>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Контент-блок -->
        <section v-if="!standaloneSession" class="bg-light pb-4 pb-lg-5">
            <b-container>
                <b-row>
                    <b-col>
                        <PostContent v-bind:dataFeed="dataFeed" v-bind:wopenTrigger="wopenTrigger"></PostContent>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        
        <!-- <section>
            <b-container>
                <b-row>
                    <b-col>
                        <AdfContentArea class="py-5" v-bind:blockName="'contentArea1'" v-bind:classIdent="'adfinity_block_13451'"></AdfContentArea>
                    </b-col>
                </b-row>
            </b-container>
        </section> -->

        <!-- Слайдер -->
        <section v-if="!standaloneSession">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="dataFeed"
                            v-bind:wopenTrigger="wopenTrigger"
                            class="mb-3"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>      

        <!-- Левый-правый кол -->
        <section v-if="!standaloneSession" class="bg-light py-3 py-sm-5">
            <b-container>
                <b-row>
                    <b-col md="6">
                        <SimpleBlock 
                            v-bind:limit="4"
                            v-bind:goalName="'HalfBlock'"
                            v-bind:dataFeed="dataFeed"
                            v-bind:wopenTrigger="wopenTrigger"
                        ></SimpleBlock>
                    </b-col>
                    <b-col md="6">
                        <SimpleBlock 
                            v-bind:limit="4"
                            v-bind:goalName="'HalfBlock'"
                            v-bind:dataFeed="dataFeed"
                            v-bind:wopenTrigger="wopenTrigger"
                        ></SimpleBlock>
                    </b-col>                    
                </b-row>
            </b-container>
        </section>

        <!-- Оверлей на темном фоне -->
        <section v-if="!standaloneSession" class="bg-dark">
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock1'"
                            v-bind:limit="6"
                            v-bind:dataFeed="dataFeed"
                            v-bind:wopenTrigger="wopenTrigger"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Текстовый -->
        <section v-if="!standaloneSession" class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlockFooter'"
                            v-bind:dataFeed="dataFeed"
                            v-bind:limit="16"
                            v-bind:wopenTrigger="wopenTrigger"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Бесконечный Оверлей (Виден Всем и Всегда)-->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="(this.STANDALONE) ? 'OverlayBlock' : 'OverlayBlockInfinity'"
                            v-bind:limit="0"
                            v-bind:dataFeed="dataFeed"
                            v-bind:wopenTrigger="wopenTrigger"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <!-- Тостер Временной интервал 15000 -->
        <ToastBlock v-if="toastTrigger"
            v-bind:limit="4"
            v-bind:goalName="'Toast'"
            v-bind:dataFeed="'smi24'"
            v-bind:timeInterval="(isDev) ? 3000 : 15000"
            v-bind:wopenTrigger="wopenTrigger"
        ></ToastBlock>        
    </div>
</template>

<script>
import utils from '@/functions/functions'
import { mapActions, mapGetters } from 'vuex'
import FlowLogic from '@/components/flow/FlowLogic.vue'
import PostContent from '@/components/content/PostContent'
import CarouselBlock from '@/components/integrations/CarouselBlock'
import SimpleBlock from '@/components/integrations/SimpleBlock'
import TickerBlock from '@/components/integrations/TickerBlock'
import ToastBlock from '@/components/integrations/ToastBlock.vue'
import BlockJumbotron from '@/components/integrations/BlockJumbotron.vue'
import TextBlock from '@/components/integrations/TextBlock.vue'
import OverlayBlock from '@/components/integrations/OverlayBlock.vue'
import FirebaseUnit from '@/components/push/FirebaseUnit.vue'
import SendGoals from '@/components/general/SendGoals.vue'
import AdFinity from '@/components/rtb/AdFinity.vue'
import bClick from '@/components/flow/bClick.vue'
// import AdfContentArea from '@/components/rtb/AdfContentArea.vue'
// import SparrowAdapt from '@/components/integrations/SparrowAdapt.vue'
export default {
    components: {
        FirebaseUnit,
        FlowLogic,
        PostContent,
        CarouselBlock,
        SimpleBlock,
        TickerBlock,
        ToastBlock,
        BlockJumbotron,
        TextBlock,
        OverlayBlock,
        SendGoals,
        AdFinity,
        bClick,
        // SparrowAdapt,
        
    },    
    data(){
        return{
            teasersCollection: [],
            postsListAPI: utils.restAPIlist(),
            needPrefetch: false,
            postId: utils.getIdFromAddressBar(),
            iDs: ['398861', '408004', '401700'],
            hasRef: (utils.isDevelopment) ? true : true, // utils.hasReferrer(),
            hardRef: (utils.isDevelopment) ? false : utils.hasReferrerHard(),
            bgSession: false,
        }
    },
    computed: {
        ...mapGetters(['MIXED_READY', 'NEXT_POST_LIST', 'PLATFORM', 'OS', 'STANDALONE', 'POSTS', 'FLOW_READY', 'BROWSER', 'DEVICE_AUTH', 
        'DF_FIRED', 'FOCUS', 'ADF_BLOCKS_READY', 'W_OPEN_STATE', 'T_OPEN_STATE', 'HAS_STORAGES']),
        testPage: function(){
            return (utils.getIdFromAddressBar() === '391530') //384809
        },
        wrapClass: function(){
            const paddingDesktop = 'pt-5 mt-2'
            const paddingMobile = 'pt-5 mt-5 pt-sm-0 mt-sm-0'
            return (this.PLATFORM === 'desktop') ? paddingDesktop : paddingMobile
        },
        dataFeed: function(){
            return (!this.hasRef) ? 'site' : 'mixed' //(utils.fromPush ? 'smi2' : 'mixed')
        },
        luckyTrigger: function(){
            return (this.hasRef && !utils.fromPush)
        },
        isFraud: function(){
            const operaMatch = this.BROWSER.match(/Opera/i)
            return (operaMatch && utils.ref24()) ? true : false
        },
        validBrowser: function(){
            const chromeMatch = this.BROWSER.match(/Chrome/i)
            const fireFoxMatch = this.BROWSER.match(/Firefox/i)
            const operaMatch = this.BROWSER.match(/Opera/i)
            const edgeMatch = this.BROWSER.match(/Edge/i)
            const yandexMatch = this.BROWSER.match(/Yandex/i)
            const safariMatch = this.BROWSER.match(/Safari/i)
            const isDesktop = (this.PLATFORM === 'desktop' /*&& !this.BROWSER.match(/Edge/i)*/)
            const isValidBrowser = (isDesktop || chromeMatch || fireFoxMatch || edgeMatch || yandexMatch || safariMatch || operaMatch)
            return (isValidBrowser) ? true : false
        },
        validId: function(){
            return (!this.iDs.includes(this.postId))
        },
        firebaseTrigger: function(){
            const swAbility = ("serviceWorker" in navigator)
            const noticeAbility = ("Notification" in window)
            // return (swAbility && noticeAbility)
            return (utils.isDevelopment) ? true : (swAbility && noticeAbility)
        },
        pwaTrigger: function(){
            return (this.hasRef && !utils.fromPush)
        },
        logicTrigger: function(){
            //innerMove
            return (this.HAS_STORAGES && this.hasRef && !utils.fromPush) ? true : false
        },
        standaloneSession: function(){
            return (this.STANDALONE && !utils.fromPush)
        },
        toastTrigger: function(){
            return (this.hasRef && !utils.fromPush)
        },
        dfTrigger: function(){
            // const a = ((!this.validBrowser || (this.bgSession && this.realMobile)) && !utils.fromPush && this.hardRef && !this.DF_FIRED && !this.STANDALONE && !utils.ref24() && !utils.refSpr())
            const a = (this.bgSession && !utils.fromPush && this.hardRef && !this.DF_FIRED && !this.STANDALONE)
            const b = (!this.DF_FIRED)
            return (utils.isDevelopment) ? (b) : (false)
        },
        realMobile: function(){
            return (this.PLATFORM != 'desktop' && this.DEVICE_AUTH)
        },
        isDev: function(){
            return utils.isDevelopment
        },
        adfinityTrigger: function(){
            return (!utils.fromPush && !this.bgSession && !this.STANDALONE) ? true : false
        },
        jumbtronTrigger: function(){
            return (this.PLATFORM === 'desktop')
        },
        dfSource: function(){
            // return (utils.ref24() ? 'infox' : 'smi24')
            // return 'smi24'
            return 'luckyShop'
        },
        operaMobile: function(){
            return (this.BROWSER.match(/Opera/i) && (this.PLATFORM != 'desktop')) ? true : false
        },
        mixerCount: function(){
            return (this.dfTrigger || utils.fromPush) ? 1 : 2
        },
        wopenTrigger: function(){
            const a = ((this.OS === 'Windows') && !this.STANDALONE && !utils.fromPush && this.hardRef) //&& br 
            return false
        },
        topenTrigger: function(){
            return (this.hasRef && !this.STANDALONE && !utils.fromPush) ? true : false
        },
        backTo: function(){
            const urlBase = (utils.isDevelopment) ? 'http://localhost' : 'https://newsonline.press'
            return `${urlBase}/today`
        }
    },
    methods: {
        ...mapActions(['FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_SMI24',
         'FETCH_INFOX', 'FETCH_LIST', 'SMI2_FETCH', 'FRAUD_DETECT', 'FETCH_GNEZDO', 
         'DF_UPDATE_STATE', 'FETCH_SPARROW', 'SET_GLOBAL_CLICK']),
        directFeedReady: function(arr){
            if (this.dfTrigger){
                utils.safeGoal('dfTrigger', this)
                utils.safeGoal('GoLink', this)
                utils.safeGoal('HardMake', this)
                this.DF_UPDATE_STATE()
                let a = (!this.isDev) ? (document.location.href = utils.shuffleArray(arr)[0].url) : console.log(utils.shuffleArray(arr)[0].url)
            }
        },
        teasersPull: function(){
            this.FEED_COUNT_RESET()
            this.FEED_SUM_DEFINE(this.mixerCount)
            if (!(this.dfTrigger || utils.fromPush)) this.FETCH_SMI24(this)
            // if (!(this.dfTrigger)) this.FETCH_INFOX(this)   
            if (!(this.dfTrigger)) this.SMI2_FETCH(this)
            // if (!(this.dfTrigger)) this.FETCH_SPARROW(this)
            if (!(this.dfTrigger)) this.FETCH_GNEZDO(this)
        },
        scrollTo: function(){
            if (localStorage.getItem('scrollHeight')){
                const scrollHeight = (localStorage.getItem('scrollHeight')) ? parseInt(localStorage.getItem('scrollHeight')) : 0
                // console.log('scrollHeight', scrollHeight)
                window.setTimeout(function(){
                    window.scrollTo(0, scrollHeight)
                }, 500)
                localStorage.removeItem('scrollHeight')
            }
        }
    },
    created: function(){
        // console.log('CREATE this.logicTrigger', this.logicTrigger)
        // console.log('CREATED this.HAS_STORAGES', this.HAS_STORAGES)
        /*
        console.log(`operaMobile ${this.operaMobile}`)
        console.log(`mixerCount ${this.mixerCount}`)
        console.log(`BROWSER ${this.BROWSER}`)
        console.log(`OS ${this.OS}`)
        console.log(`PLATFORM ${this.PLATFORM}`)
        */
        this.bgSession = (!this.FOCUS)
        utils.safeGoal((this.bgSession ? 'blurOnStart' : 'focusOnStart'), this)
        const start = (this.hasRef) ? this.teasersPull() : this.FETCH_LIST('main') //_full
        if (this.isFraud) this.FRAUD_DETECT(true)
        utils.safeGoal('pwaNew', this)
    },  
    mounted: function(){
        this.scrollTo()
        if (!this.validBrowser) utils.safeGoal('tb', this)
    },
    watch: {
        MIXED_READY: function(){
            // if (!this.NEXT_POST_LIST.length) this.FETCH_LIST('exclusive')
        },
        POSTS: function(){
            // if (this.POSTS.length && !this.NEXT_POST_LIST.length) this.FETCH_LIST('exclusive')
        },
        FLOW_READY: function(newVal, oldVal){
            if (newVal && !oldVal) utils.safeGoal('FlowReady', this)
        },
        FOCUS: function(newVal, oldVal){
            if (newVal && !oldVal) utils.safeGoal('reFocus', this)
        },
        W_OPEN_STATE: function(){
            utils.safeGoal('wOpen', this)
        }
    }
}
</script>

<style lang="sass">
$contentAnimationDuration: 2s
.app-content-preloader-wrap
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
.disappear-leave
    opacity: 1
.disappear-leave-to
    opacity: 0    
.disappear-leave-active
    transition: opacity 0.3s
.appear-enter
    opacity: 0
.appear-enter-to
    opacity: 1    
.appear-enter-active
    transition: opacity 0.8s
</style>