<template>
    <div class="mt-5 mt-sm-0 pt-5">
        <TechnicalProps></TechnicalProps>
        <!-- слайдер -->
        <section class="bg-light d-flex justify-content-center align-items-center pt-2">
            <b-container>
                <b-row>
                    <b-col>
                        <CarouselBlock 
                            v-bind:goalName="'CarouselBlock'"
                            v-bind:dataFeed="'mixed'"
                            class="mb-3"
                            v-bind:wopenTrigger="false"
                        >
                        </CarouselBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- текстовый блок -->
        <section class="pt-3">
            <b-container>
                <b-row>
                    <b-col>
                        <TextBlock
                            v-bind:goalName="'TextBlock'"
                            v-bind:dataFeed="'mixed'"
                            v-bind:limit="10"
                            v-bind:wopenTrigger="false"
                            ></TextBlock>            
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- Бесконечный Оверлей -->
        <section>
            <b-container>
                <b-row>
                    <b-col>
                        <OverlayBlock
                            v-bind:goalName="'OverlayBlock'"
                            v-bind:infinity="true"
                            v-bind:limit="0"
                            v-bind:dataFeed="'mixed'"
                            v-bind:wopenTrigger="false"
                        ></OverlayBlock>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import CarouselBlock from '../components/integrations/CarouselBlock'
import SimpleBlock from '../components/integrations/SimpleBlock'
import TextBlock from '../components/integrations/TextBlock'
import OverlayBlock from '../components/integrations/OverlayBlock'
import TechnicalProps from '../components/general/TechnicalProps'
import utils from '@/functions/functions.js'
export default {
    data(){
        return {
        }
    },
    components: {
        CarouselBlock,
        SimpleBlock,
        TextBlock,
        OverlayBlock,
        TechnicalProps
    },
    methods: {
        ...mapActions(['FETCH_SMI24', 'SMI2_FETCH', 'FEED_COUNT_RESET', 'FEED_SUM_DEFINE', 'FETCH_INFOX']),
    },
    created: function(){
        this.FEED_COUNT_RESET()
        this.FEED_SUM_DEFINE(1)
        // this.FETCH_SMI24(this)
        this.SMI2_FETCH(this)
        utils.safeGoal('TEST', this)
    },
}
</script>

<style lang="sass">

</style>